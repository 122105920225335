<template>
	<Popup v-model="news.isOpenedPopupSearchSources">
		<template v-slot:activator>
			<div @click="onOpenPopup" class="news-search-sources__btn-open">
				<template v-if="store.isMobile">
					<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.81641 21V14M4.81641 10V3M12.8164 21V12M12.8164 8V3M20.8164 21V16M20.8164 12V3M1.81641 14H7.81641M9.81641 8H15.8164M17.8164 16H23.8164" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</template>

				<template v-else>
					Media
					<span v-if="news.filters.sources?.length" class="news-search-sources__btn-count">
						{{ news.filters.sources.length }}
					</span>
					<IconArrowDown width="20px"/>
				</template>
			</div>
		</template>

		<div class="news-search-sources__market" v-if="store.isMobile">
			<NewsSwitcherMarket/>
			<i class="pointer icon-close" style="font-size: 26px" @click.stop="onClosePopup"/>
		</div>

		<div class="news-search-sources__header">
			<Input v-model="form.search" class="news-search-sources__input" placeholder="Search..." clear/>
		</div>

		<div class="news-filter-tools">
			<div class="news-filter-tools__left">
				<span class="news-filter-tools__reset pointer" @click="onReset">Reset</span>

				<Favorite
					v-model="form.isFavorited"
					size="20"
					@click="onSwitchBulkFavorite"
				/>

<!--				<svg width="14" height="14" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--					<g opacity="0.5">-->
<!--						<path d="M3.4375 5.9375L1.5625 4.0625M1.5625 4.0625L3.4375 2.1875M1.5625 4.0625H5.3125C6.30706 4.0625 7.26089 4.45759 7.96415 5.16085C8.66741 5.86411 9.0625 6.81794 9.0625 7.8125" stroke="white" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--					</g>-->
<!--				</svg>-->

<!--				<svg width="14" height="14" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--					<g opacity="0.5">-->
<!--						<path d="M7.1875 5.9375L9.0625 4.0625M9.0625 4.0625L7.1875 2.1875M9.0625 4.0625H5.3125C4.31794 4.0625 3.36411 4.45759 2.66085 5.16085C1.95759 5.86411 1.5625 6.81794 1.5625 7.8125" stroke="white" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--					</g>-->
<!--				</svg>-->
			</div>

			<NewsSwitcher
				class="news-trending__line-select"
				v-model="form.timeFrame"
				:select-items="$helpers.timeFrames"
			/>
		</div>

		<div class="media-source">
			<div class="media-source__wrapper" :class="{'media-source__wrapper_shifted': selectedService}">
				<div class="media-source__short">
					<ul>
						<template v-for="(folderService, folderServiceId) in filteredSources">
							<li v-if="folderService.isShowed" class="media-source-item" :class="{'media-source-item_opened': !!folderService.isOpened}">
								<span class="media-source-item__header">
									<CheckboxMulti v-model="folderService.isChecked" @change="onChangeFolderCheckbox(folderServiceId)"/>

									<div class="media-source-item__header-name"
										 @click="folderService.isOpened = !folderService.isOpened">
										<svg class="media-source-item__header-arrow" width="10" height="12" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M5.31125 4.32944L2.91288 6.42769C2.63025 6.67531 2.1875 6.47406 2.1875 6.09825V1.90175C2.18743 1.81764 2.2116 1.7353 2.25712 1.66458C2.30265 1.59385 2.36759 1.53775 2.44418 1.50299C2.52077 1.46823 2.60575 1.45628 2.68895 1.46857C2.77216 1.48087 2.85005 1.51689 2.91331 1.57231L5.31081 3.67056C5.3578 3.71163 5.39547 3.76227 5.42127 3.81909C5.44708 3.87591 5.46043 3.93759 5.46043 4C5.46043 4.06241 5.44708 4.12409 5.42127 4.18091C5.39547 4.23773 5.3578 4.28837 5.31081 4.32944H5.31125Z" fill="white"/>
										</svg>
										{{folderService.name}}
									</div>

									<span v-if="folderService.countCheckedChildren" class="media-source-item__btn-count">
										{{folderService.countCheckedChildren}}
									</span>

									<Favorite
										v-model="folderService.isFavorited"
										:service-id="folderServiceId"
										class="media-source-item__favorite"
										size="20"
									/>
								</span>

								<ul class="media-source-item__children">
									<template v-for="(influencer, index) in folderService.shortInfluencers">
										<li v-if="influencer?.isShowed"
											class="media-influencer-item"
											:class="{
												'media-influencer-item_opened': !!influencer.isOpened,
												// 'media-influencer-item_has-children': Object.keys(influencer.services).length > 1
											}">

											<span class="media-influencer-item__header">
												<Checkbox v-if="influencer.ref.services[folderServiceId].length" v-model="influencer.ref.services[folderServiceId][0].isChecked" @change="onChangeCheckbox(folderServiceId)"/>

												<div class="media-influencer-item__header-name"
													 :class="{'media-influencer-item__header-name_opening': Object.keys(influencer.ref.services).length > 1}"
													@click="influencer.isOpened = !influencer.isOpened">

													<svg v-if="Object.keys(influencer.ref.services).length > 1" class="media-influencer-item__header-arrow" width="10" height="12" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M5.31125 4.32944L2.91288 6.42769C2.63025 6.67531 2.1875 6.47406 2.1875 6.09825V1.90175C2.18743 1.81764 2.2116 1.7353 2.25712 1.66458C2.30265 1.59385 2.36759 1.53775 2.44418 1.50299C2.52077 1.46823 2.60575 1.45628 2.68895 1.46857C2.77216 1.48087 2.85005 1.51689 2.91331 1.57231L5.31081 3.67056C5.3578 3.71163 5.39547 3.76227 5.42127 3.81909C5.44708 3.87591 5.46043 3.93759 5.46043 4C5.46043 4.06241 5.44708 4.12409 5.42127 4.18091C5.39547 4.23773 5.3578 4.28837 5.31081 4.32944H5.31125Z" fill="white"/>
													</svg>

													{{influencer.ref.name}}

													<span v-if="influencer.ref.services[folderServiceId][0]?.stats[form.timeFrame]" class="media-influencer-item__count">({{influencer.ref.services[folderServiceId][0].stats[form.timeFrame]}})</span>
												</div>

												<Favorite
													v-model="influencer.ref.services[folderServiceId][0].isFavorited"
													:service-id="folderServiceId"
													:source-id="influencer.ref.services[folderServiceId][0].id"
													class="media-influencer-item__favorite"
												/>
											</span>

											<ul v-if="Object.keys(influencer.ref.services).length > 1" class="media-influencer-item__children">
												<template v-for="(service, serviceId) in influencer.ref.services">
													<li v-if="folderServiceId !== serviceId" class="media-service-item" :title="service[0].name">
														<Checkbox v-model="service[0].isChecked" @change="onChangeCheckbox(serviceId)">
															<i :class="`icon icon-${$helpers.iconNameService[serviceId]}`"/>
														</Checkbox>

														<Favorite
															v-model="service[0].isFavorited"
															:service-id="serviceId"
															:source-id="service[0].id"
															size="14"
														/>
													</li>
												</template>
											</ul>
										</li>
									</template>
									<li class="btn-show-more link" @click="onShowAll(folderService)">
										Show all ({{Object.keys(folderService.influencers).length}})
									</li>
								</ul>
							</li>
						</template>
					</ul>
				</div>

				<div class="media-source__extended">
					<div class="media-source__extended-header">
						<div class="btn-back" @click="selectedService = null">
							<i class="icon-arrow-left-2" style="font-size: 20px"></i>
							Back
						</div>
					</div>

					<ul v-if="selectedService">
						<li v-if="selectedService?.isShowed" class="media-source-item" :class="{'media-source-item_opened': !!selectedService.isOpened}">
							<span class="media-source-item__header">
								<CheckboxMulti v-model="selectedService.isChecked" @change="onChangeFolderCheckbox(selectedService.id)"/>

								<div class="media-source-item__header-name">
									{{selectedService.name}}
								</div>

								<span v-if="selectedService.countCheckedChildren" class="media-source-item__btn-count">
									{{selectedService.countCheckedChildren}}
								</span>

								<Favorite
									v-model="selectedService.isFavorited"
									:service-id="selectedService.id"
									class="media-source-item__favorite"
									size="20"
								/>
							</span>

							<ul class="media-source-item__children">
                                <li v-if="selectedService.favorites" class="media-source-item__title-favorites">
                                    Favorites
                                </li>

								<template v-for="(influencer, index) in selectedService.influencers">
									<li v-if="influencer?.isShowed"
										class="media-influencer-item"
										:class="{
											'media-influencer-item_opened': !!influencer.isOpened,
											'media-influencer-item_last-favorites': index + 1 === selectedService.favorites,
										}">

										<span class="media-influencer-item__header">
											<Checkbox v-if="influencer.ref.services[selectedService.id].length" v-model="influencer.ref.services[selectedService.id][0].isChecked" @change="onChangeCheckbox(selectedService.id)"/>

											<div class="media-influencer-item__header-name"
												 :class="{'media-influencer-item__header-name_opening': Object.keys(influencer.ref.services).length > 1}"
												 @click="influencer.isOpened = !influencer.isOpened">

												<svg v-if="Object.keys(influencer.ref.services).length > 1" class="media-influencer-item__header-arrow" width="10" height="12" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M5.31125 4.32944L2.91288 6.42769C2.63025 6.67531 2.1875 6.47406 2.1875 6.09825V1.90175C2.18743 1.81764 2.2116 1.7353 2.25712 1.66458C2.30265 1.59385 2.36759 1.53775 2.44418 1.50299C2.52077 1.46823 2.60575 1.45628 2.68895 1.46857C2.77216 1.48087 2.85005 1.51689 2.91331 1.57231L5.31081 3.67056C5.3578 3.71163 5.39547 3.76227 5.42127 3.81909C5.44708 3.87591 5.46043 3.93759 5.46043 4C5.46043 4.06241 5.44708 4.12409 5.42127 4.18091C5.39547 4.23773 5.3578 4.28837 5.31081 4.32944H5.31125Z" fill="white"/>
												</svg>

												{{influencer.ref.name}}

												<span v-if="influencer.ref.services[selectedService.id][0]?.stats[form.timeFrame]" class="media-influencer-item__count">({{influencer.ref.services[selectedService.id][0].stats[form.timeFrame]}})</span>
											</div>

											<Favorite
												v-model="influencer.ref.services[selectedService.id][0].isFavorited"
												:service-id="selectedService.id"
												:source-id="influencer.ref.services[selectedService.id][0].id"
												class="media-influencer-item__favorite"
											/>
										</span>

										<ul v-if="Object.keys(influencer.ref.services).length > 1" class="media-influencer-item__children">
											<template v-for="(service, serviceId) in influencer.ref.services">
												<li v-if="selectedService.id !== serviceId" class="media-service-item" :title="service[0].name">
													<Checkbox v-model="service[0].isChecked" @change="onChangeCheckbox(serviceId)">
														<i :class="`icon icon-${$helpers.iconNameService[serviceId]}`"/>
													</Checkbox>

													<Favorite
														v-model="service[0].isFavorited"
														:service-id="serviceId"
														:source-id="service[0].id"
														size="14"
													/>
												</li>
											</template>
										</ul>
									</li>
								</template>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="news-search-sources__footer">
			<Button size="s" @click="onClickApply">{{ $t('apply') }}</Button>
		</div>
	</Popup>
</template>

<script>
import {useNews} from '~/stores/news';
import {useStore} from '~/stores/store';
import Button from '../UI/Button.vue';

export default {
	name: 'NewsSearchSources',
	setup() {
		const store = useStore();
		const news = useNews();

		return {
			store,
			news
		}
	},
	data() {
		return {
			selectedService: null,
			form: {
				isFavorited: false,
				search: null,
				timeFrame: '1d'
			}
		}
	},
	computed: {
		filteredSources() {
			console.time('filteredSources')
			let filteredSources = this.news.folderServices;

			// if (this.form.isFavorited) {
			// 	influencers = this.influencers.filter(item => this.news.favorites.sources.includes(item.services[0].id))
			// }

			if (this.form.search?.length) {
                const influencersIds = this.news.influencers
                    .filter(item => item.name.toLowerCase().includes(this.form.search.toLowerCase()))
                    .map(item => item.id)

                for (const serviceId in filteredSources) {
                    let isShowed = false;

                    for (const influencer of filteredSources[serviceId].influencers) {
						influencer.isShowed = influencersIds.includes(influencer.ref.id);
                        isShowed = isShowed || influencer.isShowed;
                    }
                    filteredSources[serviceId].isShowed = isShowed;

					this.sortInfluencers(filteredSources[serviceId].influencers, serviceId);
					const shortInfluencers = this.sortShortInfluencers(filteredSources[serviceId].influencers, serviceId);

					filteredSources[serviceId].shortInfluencers = shortInfluencers.filter(item => item.isShowed).splice(0, 5);
                }
			} else {
                for (const serviceId in filteredSources) {
                    filteredSources[serviceId].isShowed = true;
                    let countFavorites = 0;

                    for (const influencer of filteredSources[serviceId].influencers) {
                        influencer.isShowed = true;

                        countFavorites += influencer.ref.services[serviceId][0].isFavorited ? 1 : 0;
                    }
                    filteredSources[serviceId].favorites = countFavorites;

                    this.sortInfluencers(filteredSources[serviceId].influencers, serviceId);
					const shortInfluencers = this.sortShortInfluencers(filteredSources[serviceId].influencers, serviceId);

					filteredSources[serviceId].shortInfluencers = shortInfluencers.slice(0, 5);
				}
            }
			console.timeEnd('filteredSources')
			return filteredSources;
		}
	},
	mounted() {
		// this.news.checkCheckedAllFolders();
	},
	methods: {
		onClickApply() {
			this.news.findCheckedAndSetFilters();

      if (this.news.isFiltersUpdated()) {
          this.news.setURL();
      }
			this.news.isOpenedPopupSearchSources = false;
		},
		onOpenPopup() {
			this.news.isOpenedPopupSearchSources = !this.news.isOpenedPopupSearchSources;
		},
		onClosePopup() {
			this.news.isOpenedPopupSearchSources = false;
		},
		onReset() {
			// console.log('onReset()')
			this.form.isFavorited = false;

			if (!this.news.checkEmptyFilters()) {
				this.news.uncheckedServicesAndSources();
				this.news.findCheckedAndSetFilters();
			}
		},
		onShowAll(folderService) {
			this.selectedService = folderService;
		},

		// Favorites
		onSwitchBulkFavorite(newValue) {
			console.log('onSwitchBulkFavorite()', newValue);
			// this.form.isFavorited = newValue;

			if (this.form.isFavorited) {
				for (const serviceId in this.news.folderServices) {
					if (this.news.favorites.services.has(serviceId)) {
						this.news.folderServices[serviceId].isChecked = 1;
					} else {
						this.news.folderServices[serviceId].isChecked = 0;
					}

					for (const influencer of this.news.folderServices[serviceId].influencers) {
						if (influencer.ref.services[serviceId][0].isFavorited) {
							influencer.ref.services[serviceId][0].isChecked = true;
						} else {
							influencer.ref.services[serviceId][0].isChecked = false;
						}
					}
				}

				this.news.checkCheckedAllFolders();
				this.news.findCheckedAndSetFilters();
			} else {
				this.news.uncheckedServicesAndSources();
				this.news.findCheckedAndSetFilters();
			}
		},
		checkStatusBulkFavorite() {
			let arrFilters = this.news.filters.sources.map(item => item.id);
			let arrFavorites = [...this.news.favorites.sources];

			let symmetricDifference = arrFilters
				.filter(x => !arrFavorites.includes(x))
				.concat(arrFavorites.filter(x => !arrFilters.includes(x)));

			if (symmetricDifference.length) {
				this.form.isFavorited = false;
			}

			arrFilters = this.news.filters.services.map(item => item.id);
			arrFavorites = [...this.news.favorites.services];

			symmetricDifference = arrFilters
				.filter(x => !arrFavorites.includes(x))
				.concat(arrFavorites.filter(x => !arrFilters.includes(x)));

			if (symmetricDifference.length) {
				this.form.isFavorited = false;
			}
		},

		// Checkboxes
		onChangeFolderCheckbox(serviceId) {
			this.news.onChangeFolderCheckbox(serviceId);

			this.checkStatusBulkFavorite();
		},
		onChangeCheckbox(serviceId) {
			this.news.onChangeCheckbox(serviceId);

			this.checkStatusBulkFavorite();
		},
		sortInfluencers(influencers, serviceId) {
			return influencers.sort((a, b) => {
				if (a.ref.services[serviceId][0].isFavorited > b.ref.services[serviceId][0].isFavorited) {
					return -1;
				} else if (a.ref.services[serviceId][0].isFavorited < b.ref.services[serviceId][0].isFavorited) {
					return 1;
				}

                return a.ref.name.toLowerCase().localeCompare(b.ref.name.toLowerCase());
			})
		},
		sortShortInfluencers(influencers, serviceId) {
			return influencers.toSorted((a, b) => {
				if (a.ref.services[serviceId][0].stats[this.form.timeFrame] === undefined && b.ref.services[serviceId][0].stats[this.form.timeFrame] === undefined) {
					return a.ref.name.toLowerCase().localeCompare(b.ref.name.toLowerCase());
				}

				if (typeof a.ref.services[serviceId][0].stats[this.form.timeFrame] === 'number' && b.ref.services[serviceId][0].stats[this.form.timeFrame] === undefined
					|| a.ref.services[serviceId][0].stats[this.form.timeFrame] > b.ref.services[serviceId][0].stats[this.form.timeFrame]) {
					return -1;
				} else if (a.ref.services[serviceId][0].stats[this.form.timeFrame] === undefined && typeof b.ref.services[serviceId][0].stats[this.form.timeFrame] === 'number'
					|| a.ref.services[serviceId][0].stats[this.form.timeFrame] < b.ref.services[serviceId][0].stats[this.form.timeFrame]) {
					return 1;
				}

				return 0;
			})
		}
	}
}
</script>

<style scoped lang="scss">
:deep(.popup__container) {
	display: flex;
	flex-direction: column;
	width: 400px;
	overflow: hidden;

	@media (max-width: 517px) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: auto;
		max-height: none;
	}
}

.icon {
	color: $neutrals3;

	&:before {
		font-size: 20px;
	}
}

.news-search-sources {
	&__market {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		width: 100%;
		margin-bottom: 20px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		width: 100%;
		margin-bottom: 20px;
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		padding-top: 10px;
	}

	&__btn-open {
		display: flex;
		align-items: center;
		gap: 5px;
		cursor: pointer;
	}

	&__btn-count {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 18px;
		height: 18px;
		padding: 0 5px;
		border-radius: 30px;
		background-color: $white;
		color: $dark;
		font-size: 12px;
		font-weight: bold;
	}

	&__input {
		width: 100%;
	}
}

.news-filter-tools {
    display: flex;
    justify-content: space-between;
    margin-block: 20px;

	&__left {
		display: flex;
		//align-items: center;
		gap: 10px;
	}

    &__reset {
        color: $neutrals2;
        cursor: pointer;
        transition: color .25s;

        &:hover {
            color: $white;
        }
    }
}

.btn-show-more {
	line-height: 32px;
	margin-bottom: 20px;
}

.btn-back {
	display: flex;
	align-items: center;
	line-height: 32px;
	cursor: pointer;
}

.media-source {
	//overflow-y: auto;
	//overflow-x: hidden;
	overflow: hidden;
	min-height: 180px;
	max-height: 480px;

	@media (max-width: 512px) {
		max-height: none;
	}

	&__wrapper {
		display: flex;
		width: calc(200% + 20px);
		gap: 20px;
		transition: translate .5s;

		&_shifted {
			translate: calc(-50% - 10px);
		}
	}

	&__short {
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		max-height: 280px;

		@media (max-width: 512px) {
			max-height: calc(100vh - 260px);
		}
	}

	&__extended {
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		max-height: 280px;
		padding-top: 32px;

		& .media-source-item_opened > .media-source-item__children {
			max-height: none;
		}

		@media (max-width: 512px) {
			max-height: calc(100vh - 260px);
		}
	}

	&__extended-header {
		position: absolute;
		top: 0;
		z-index: 1;
		width: 100%;
		background-color: $dark;
	}
}

.media-source-item {
	&__header {
		display: flex;
		align-items: center;
		gap: 8px;
		min-height: 32px;
		cursor: pointer;
	}

	&__header-name {
		flex-grow: 1;
	}

	&__header-arrow {
		transition: rotate .3s;
	}

	&__btn-count {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 18px;
		height: 18px;
		padding: 0 5px;
		margin-right: 3px;
		border-radius: 30px;
		background-color: $white;
		color: $dark;
		font-size: 12px;
		font-weight: bold;
	}

    &__title-favorites {
        margin-block: 10px 3px;
        color: $neutrals3;
    }

    &__children {
		margin-left: 18px;
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;
	}

	&_opened > &__children {
		max-height: 400px;
	}

	&_opened > &__header > &__header-name > &__header-arrow {
		rotate: 90deg;
	}
}

.media-influencer-item {
	&__header {
		display: flex;
		align-items: center;
		gap: 8px;
		min-height: 32px;
		cursor: default;
	}

	&__header-arrow {
		transition: rotate .3s;
	}

	&__header-name {
		flex-grow: 1;

		&_opening {
			cursor: pointer;
		}
	}

	&__count {
		color: $neutrals3;
	}

	&__children {
		display: flex;
		gap: 20px;
		height: 0;
		margin-left: 18px;
		overflow: hidden;
		transition: height .3s;
	}

    &_last-favorites {
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid $dark-2;
    }

    &_opened > &__children {
		height: 30px;
	}

	&_opened > &__header > &__header-name > &__header-arrow {
		rotate: 90deg;
	}
}

.media-service-item {
	position: relative;
	display: flex;
	align-items: center;
	gap: 5px;

	:deep(.checkbox) {
		padding-left: 25px;
	}

	.icon-favorite {
		position: absolute;
		top: 0;
		right: -14px;
	}
}
</style>
