<template>
	<div :class="classNames" :style="{ width: width }">
		<slot name="activator" :onOpen="onOpen">
            <Button
                class="form-input"
                :disabled="disabled"
                @blur="onClose"
                @focus="onOpen">
                Popup
            </Button>
		</slot>

        <div class="popup__container" :class="classList" :style="popupContainerStyle" ref="popupContainer">
            <slot/>
        </div>
	</div>
</template>

<script>
import {useStore} from '~/stores/store';

export default {
	name: 'Popup',
	props: {
		modelValue: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		align: {
			type: String,
			default: 'left',
		},
		icon: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: null,
		},
	},
	setup() {
		const store = useStore();

		return {
			store,
		};
	},
	data() {
		return {
			opened: false,
			popupContainerStyle: {
				top: null,
				left: null,
				right: null,
				bottom: null,
			},
		};
	},
	computed: {
        classNames() {
			return {
				popup: true,
                popup_icon: this.icon,
				popup_opened: this.opened,
			};
		},
		classList() {
			return {
				popup__container_align_right: this.align === 'right',
			};
		},
	},
	watch: {
		modelValue(newValue) {
			// console.log('Popup modelValue watch', newValue)
			this.opened = newValue;
		},
		opened(val) {
			// console.log('Popup opened watch', val)
			const modals = document.querySelectorAll('.popup_opened');
			// console.log(modals.length)
			if (!val) {
				setTimeout(() => {
					this.popupContainerStyle = {
						top: null,
						left: null,
						right: null,
						bottom: null,
					};
				}, 300);
			}

			if (val) {
				this.adjustPopupPosition();
				document.body.classList.add('modal-open');
				return;
			}
			if (modals.length < 2) {
				document.body.classList.remove('modal-open');
			}
		}
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
	},
	methods: {
		onOpen(event) {
			// console.log('onOpen()')
			event.preventDefault();

			if (this.opened) {
				this.opened = false;
			} else {
				this.opened = true;

				if (this.modelValue !== null && event) {
					this.$emit('update:modelValue', this.opened);
				}
			}
		},
		onClose() {
			// console.log('onClose()')
			setTimeout(() => {
				this.opened = false;
			}, 100);
		},
		handleClickOutside(evt) {
			// console.log('handleClickOutside()')
			if (!this.$el.contains(evt.target)) {
				this.opened = false;
				this.$emit('update:modelValue', this.opened);
				// this.activeItem = -1
			}
		},
		adjustPopupPosition() {
			if (this.store.isMobile) return;
      if (!this.opened) return;

			const popupRef = this.$refs.popupContainer;
			if (!popupRef) return;

			let top = null;
			let left = null;
			let right = null;
			let bottom = null;

			const rect = popupRef.getBoundingClientRect();
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;

			if (this.align === 'right' && rect.left < 20) {
				right = rect.left - 20;
				left = null;
			}

			if (this.align === 'left' && rect.right > viewportWidth - 20) {
				left = viewportWidth - 20 - rect.right;
				right = null;
			}

			if (rect.top < 20) {
				top = 20;
			}

			if (rect.bottom > viewportHeight) {
				bottom = 20;
			}

      this.popupContainerStyle.top = top === null ? null : `${top}px`;
			this.popupContainerStyle.left = left === null ? null : `${left}px`;
			this.popupContainerStyle.right = right === null ? null : `${right}px`;
			this.popupContainerStyle.bottom = bottom === null ? null : `${bottom}px`;
    }
	},
	destroyed() {
		document.removeEventListener('click', this.handleClickOutside);
	},
};
</script>

<style lang="scss">
.popup {
	position: relative;
	display: inline-block;
}
.popup__container {
	position: absolute;
	top: calc(100% + 7px);
	z-index: 1000;
	min-width: 165px;
	max-height: 0;
    padding: 20px;
    border: 1px solid $dark-2;
	background: $dark;
	box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
	overflow-y: hidden;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s 0.1s, visibility 0s 0.4s, max-height 0.3s;
	display: flex;
	flex-direction: column;
}
.popup__container_align_right {
	right: 0;
}

.popup_opened > .popup__container {
	overflow-y: auto;
	max-height: 490px;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.3s, visibility 0s, max-height 0.3s;
}
.popup_opened > .form-input {
	border-color: $primary1;
}
.popup_opened :deep(.arrow),
.popup_opened .popup__container:after {
	transform: rotate(-180deg);
}
</style>
