<template>
	<div class="news-articles-list" ref="newsArticlesListRef" @scroll="handleScroll">
        <div v-if="loading" class="news-articles-list__loader">
            <LoaderQuad/>
        </div>

		<div v-else class="news-articles-list__wrapper">
			<div
				v-for="article in articles"
				:key="article.id"
				class="news-article-item"
				:class="{'news-article-item_selected': article === activeArticle}"
				@click="selectArticleByUrl(article.slug)"
			>
				<div class="news-article-item__left">
					{{ $helpers.diffDates(article.published_date) }}
				</div>

				<div class="news-article-item__right">
					<h2 class="news-article-item__title" v-html="article.title"></h2>

					<NuxtLink
						:to="`/news/${article.source.influencer.slug}`"
						class="news-article-item__service link link_color_gray"
						:class="`icon-${$helpers.iconNameService[article.source.service_type]}`"
						@click.stop>

						{{ article.source.influencer.name }}
					</NuxtLink>

					<NewsTags class="news-article-item__tags" :tags="article.tags"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const dayjs = useDayjs();
import {useApi} from '~/composables/api';
import {useNews} from '~/stores/news';

export default {
	name: 'NewsListArticles',
	setup() {
		const news = useNews();

		return {
			news
		};
	},
	data() {
		return {
            isLoading: false,
            activeArticle: null,
            articles: [],
			nextPage: null,
			lastFilters: null
		}
	},
	watch: {
		'news.filters': {
			handler(val) {
				// console.log('%cwatch() ListArticles news.filters', 'color:red', val)
                this.fetchNews();
			},
			deep: true
		}
	},
    computed: {
        loading() {
            return this.isLoading && !this.articles.length;
        }
    },
	created() {
		this.fetchNews();
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		dayjs,
		selectArticleByUrl(slug) {
			const article = this.articles.find((item) => item.slug === slug);

			if (article) {
				// console.log('push', slug)
				this.activeArticle = article;
				this.$router.push(`/news/p/${slug}`);
				// window.history.pushState({}, '', `/news/${url}`);
			}
		},
		async fetchNews() {
            try {
                this.isLoading = true;
                const api = useApi();
                const isNewPage = this.lastFilters !== JSON.stringify(this.news.filters);

                if (isNewPage) {
                    this.articles = [];
                    this.nextPage = 1;
                }

                const response = await api.news.fetchNews({...this.news.filters, page: this.nextPage});

                if (response) {
                    // console.log('fetchNews', response.result)
                    this.articles = isNewPage ? response.result : [...this.articles, ...response.result];
                    this.nextPage = response.next_page;
                    this.lastFilters = JSON.stringify(this.news.filters);

                    if (response?.filters?.filter_id) {
                        // this.news.filters.filterId = response.filters.filter_id;
                    }

                    setTimeout(() => {
                        this.handleScroll();
                    }, 500)

                }
            } finally {
                this.isLoading = false;
            }
		},
		handleScroll() {
			if (process.client) {
				const newsArticlesListRef = this.$refs.newsArticlesListRef;
				const isBottom = newsArticlesListRef.scrollHeight - newsArticlesListRef.scrollTop - newsArticlesListRef.clientHeight < 150;

				if (isBottom && !this.isLoading && this.nextPage) {
					this.fetchNews();
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.news-articles-list {
	overflow-y: auto;
	max-height: calc(100vh - 129px);

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 350px;
    }
}

.news-article-item {
	display: flex;
	padding: 10px 13px 12px;

	@media (max-width: 517px) {
		padding-inline: 20px;
	}

	&:hover {
		cursor: pointer;
		background: rgba(15, 29, 39, 0.60);
	}

	&__left {
		display: flex;
		min-width: 57px;
		padding-top: 5px;
		line-height: 1.5em;
		color: #63737C;
	}

	&__title {
		display: inline-block;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.5em;
	}

	&__service {
		display: inline-flex;
		align-items: baseline;
		gap: 5px;
		margin-left: 10px;
		white-space: nowrap;

		&:before {
			position: relative;
			top: 4px;
			font-size: 22px;
		}
	}

	&__tags {
		margin-top: 5px;
	}

	&__tag {
		color: #309BFE;
		text-decoration: none;
		transition: all 0.3s ease-in-out;
	}

	&_selected {
		background: rgba(15, 29, 39, 0.60);
	}
}

</style>
