<template>
	<div
		v-show="tags?.length"
		:style="{ maxHeight: expandingBtn ? `${mediaTagsMaxHeight}px` : 'none' }"
		class="media-tags"
	>
		<div
			class="media-tags__content"
			:class="{ 'media-tags__content_has-more-btn': expandingBtn }"
			ref="mediaTagsContent"
		>
			<div
				v-for="tag in tags"
				class="media-tag"
				:class="{ 'media-tag_clickable': ableToClick }"
				@click="onClickTag(tag)"
			>
				<template v-if="tag?.type === 'source'">
					<i :class="`icon icon-${$helpers.iconNameService[tag.service_type]}`" />
					{{ tag.influencer.name }}
				</template>

				<template v-else-if="tag?.type === 'service'">
					<i :class="`icon icon-${$helpers.iconNameService[tag.id]}`" />
					{{ tag.name }}
				</template>

				<template v-else>
					# {{ tag.name }}
				</template>

				<IconClose
					v-if="ableToRemove"
					@click.stop="onRemoveTag(tag)"
				/>
			</div>
			<span
				v-if="tags?.length && ableToRemove"
				class="media-tags__clear-tags icon-delete"
				@click.stop="onClearTags"
			></span>
		</div>
		<div
			v-if="isDisplayShowMoreContent"
			class="media-tags__more-content"
			:class="{ 'media-tags__more-content_rotate': opened }"
			@click="onClickMoreContent()"
		>
			<svg
				width="30"
				height="30"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5 7.5L10 12.5L15 7.5"
					stroke="white"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	</div>
</template>

<script>
import { useStore } from '~/stores/store';
import { useNews } from '~/stores/news';

export default {
	name: 'NewsMediaTags',
	props: {
		tags: {
			type: Array,
			required: true
		},
		ableToRemove: {
			type: Boolean,
			default: true,
		},
		ableToClick: {
			type: Boolean,
			default: false,
		},
		expandingBtn: {
			type: Boolean,
			default: false
		}
	},
	setup() {
		const store = useStore();
		const news = useNews();

		return {
			store,
			news
		};
	},
	data: () => ({
		mediaTagsContentRefHeight: 0,
		mediaTagsMaxHeight: 0,
		calcMaxHeightForClosedContent: 33,
		opened: false,
	}),
	computed: {
		isDisplayShowMoreContent() {
			return this.expandingBtn && this.mediaTagsContentRefHeight > this.calcMaxHeightForClosedContent;
		},
	},
	watch: {
		tags() {
			this.$nextTick(() => this.setmediaTagsContentRefHeightByOffsetHeight());
		}
	},
	mounted() {
		if (this.expandingBtn) {
			this.handleResize();
			this.mediaTagsMaxHeight = this.calcMaxHeightForClosedContent;

			// window.addEventListener('resize', this.handleResize);
		}
	},
	unmounted() {
		if (this.expandingBtn) {
			// window.removeEventListener('resize', this.handleResize);
		}
	},
	methods: {
		onRemoveTag(tag) {
			this.$emit('removeTag', tag);
		},
		onClearTags() {
			this.$emit('clearTags');
		},
		onClickTag(tag) {
			this.$emit('clickTag', tag);
		},
		handleResize() {
			this.setmediaTagsContentRefHeightByOffsetHeight();
		},
		setmediaTagsContentRefHeightByOffsetHeight() {
			const element = this.$refs.mediaTagsContent;

			if (element) {
				this.mediaTagsContentRefHeight = this.$refs.mediaTagsContent?.offsetHeight ?? 0;
			}
		},
		onClickMoreContent() {
			this.opened = !this.opened;
			this.mediaTagsMaxHeight = this.opened ? this.mediaTagsContentRefHeight + 40 : this.calcMaxHeightForClosedContent;
		}
	}
}
</script>

<style
	lang="scss"
	scoped
>
.media-tags {
	display: flex;
	min-height: 33px;
	transition: all 0.5s ease-in-out;
	overflow: hidden;
	flex-shrink: 0;

	&__content {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 8px;
		height: 100%;
		max-height: 95px;
		overflow-y: scroll;

		&_has-more-btn {
			height: max-content;
			max-height: unset;
			overflow-y: hidden;
		}
	}

	&__more-content {
		margin-inline: auto;
		width: 30px;

		&:hover {
			cursor: pointer;
		}

		svg {
			position: relative;
			top: 2px;
			transition: all 0.5s ease-in-out;
		}

		&_rotate svg {
			transform: rotateX(180deg);
		}
	}

	&__clear-tags {
		font-size: 24px;
		cursor: pointer;
		color: $neutrals3;
		transition: color .25s;

		&:hover {
			color: $white;
		}
	}
}

.media-tag {
	display: flex;
	gap: 7px;
	height: 32px;
	padding: 5px 8px;
	border: 1px solid $dark-2;
	background-color: $dark;
	color: $neutrals3;
	line-height: 20px;

	& .icon:before {
		font-size: 22px;
		line-height: 18px;
	}

	&_clickable {
		cursor: pointer;
		transition: background-color .25s;

		&:hover {
			background-color: $dark-2;
		}
	}
}
</style>
