<template>
    <svg stroke="currentColor" width="20" height="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <path d="m7 7 18 18"/>
            <path d="m7 25 18-18"/>
        </g>
    </svg>
</template>
<script>
export default {
	name: 'IconClose',
};
</script>
<style lang="scss" scoped>
svg {
    cursor: pointer;
    transition: stroke .2s;
}
svg:hover {
    stroke: $white;
}
</style>
