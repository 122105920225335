<template>
	<Popup
		v-model="news.isOpenedPopupSearchTags"
		align="right"
	>
		<template v-slot:activator>
			<div
				@click="onOpenPopup"
				class="news-search-tags__btn-open"
			>
				<IconSearch
					v-if="store.isMobile"
					style="width: 22px"
				/>

				<template v-else>
					<IconSearch style="width: 18px" />
					Search
				</template>
			</div>
		</template>

		<div class="news-search-tags__header">
			<Input
				v-model="form.search"
				class="news-search-tags__input"
				placeholder="Search..."
				clear
			/>
			<i
				class="pointer icon-close"
				style="font-size: 26px"
				@click.stop="onClosePopup"
			/>
		</div>

		<NewsMediaTags
			:tags="bufferTags"
			@remove-tag="onRemoveTag"
			@clear-tags="onClearTags"
			class="news-search-tags__media-tags"
		/>

		<div class="news-filter-tools">
			<NewsSwitcher
				class="news-trending__line-select"
				v-model="form.timeFrame"
				:select-items="$helpers.timeFrames"
			/>
		</div>

		<div
			class="tags-container"
			ref="tags-container-ref"
			@scroll="this.handleScroll"
		>
			<table>
				<thead>
					<tr class="tag-header">
						<th></th>
						<th class="tag-item__count icon icon-article" />
						<th class="tag-item__count icon icon-tiktok" />
						<th class="tag-item__count icon icon-youtube" />
						<th class="tag-item__count icon icon-twitter" />
						<th class="tag-item__count icon icon-telegram" />
						<th class="tag-item__count icon icon-medium" />
					</tr>
				</thead>

				<tbody>
					<tr
						v-for="tag in filteredTags"
						class="tag-item"
						@click.stop="onSelectTag(tag)"
					>
						<td class="tag-item__header">
							{{ tag.name }}
						</td>

						<td
							v-for="serviceType in $helpers.servicesTypes"
							class="tag-item__count"
						>
							<template v-if="tag.services[serviceType]">
								{{ $helpers.formatFollowers(tag.services[serviceType][form.timeFrame]) }}
							</template>

							<template v-else>-</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="news-search-tags__footer">
			<div
				v-if="recentTags.length"
				class="news-search-tags__footer-tags"
			>
				<div class="news-search-tags__footer-title">
					{{ $t('Recent searches:') }}
				</div>
				<NewsMediaTags
					:tags="recentTags"
					:ableToRemove="false"
					:ableToClick="true"
					:expandingBtn="true"
					@clickTag="onSelectTag"
				/>
			</div>
			<div
				class="news-search-tags__footer-btns"
				:class="{ 'news-search-tags__footer-btns_not-empty': recentTags.length }"
			>
				<Button
					size="s"
					@click="onClickApply"
				>{{ $t('apply') }}</Button>
			</div>

		</div>
	</Popup>
</template>

<script>
import { useNews } from '~/stores/news';
import { useStore } from '~/stores/store';
import Button from '../UI/Button.vue';

export default {
	name: 'NewsSearchTags',
	setup() {
		const news = useNews();
		const store = useStore();

		return {
			store,
			news
		}
	},
	data() {
		return {
			form: {
				search: null,
				timeFrame: '1d'
			},
			bufferTags: [],
			recentTags: [],
			page: 1,
			limit: 20
		}
	},
	computed: {
		filteredTags() {
			let filteredTags = [...this.news.tags];
			const arrTagsIds = this.bufferTags.map(item => item.id);

			filteredTags = filteredTags.filter(item => !arrTagsIds.includes(item.id))

			if (this.form.search?.length) {
				filteredTags = filteredTags.filter(child => child.name.toLowerCase().includes(this.form.search.toLowerCase()))
			}

			filteredTags = filteredTags.slice(0, this.page * this.limit);

			return filteredTags;
		}
	},
	watch: {
		'news.filters.tags'(newVal, oldVal) {
			this.bufferTags = [...newVal];
		},
		'news.recentTags'(newVal, oldVal) {
			this.recentTags = newVal;
			localStorage.setItem('recentTags', JSON.stringify(newVal));
		},
	},
	created() {
		this.bufferTags = [...this.news.filters.tags];
	},
	mounted() {
		try {
			let recentTags = this.news.recentTags;
			if (!recentTags.length) {
				recentTags = JSON.parse(localStorage.getItem('recentTags')) || [];
				this.news.setRecentTags(recentTags);
			}
			this.recentTags = recentTags;
		} catch (e) {
			console.error(e);
		}
	},
	methods: {
		onClickApply() {
			this.news.setTags(this.bufferTags.map(item => item.id));
			this.news.addRecentTags(this.bufferTags);

			if (this.news.isFiltersUpdated()) {
				this.news.setURL();
			}
			this.news.isOpenedPopupSearchTags = false;
		},
		handleScroll() {
			const tagContainerRef = this.$refs['tags-container-ref'];
			const isBottom = tagContainerRef.scrollHeight - tagContainerRef.scrollTop - tagContainerRef.clientHeight < 50;

			if (isBottom) {
				this.page++;
			}
		},
		onOpenPopup() {
			this.news.isOpenedPopupSearchTags = !this.news.isOpenedPopupSearchTags;
		},
		onClosePopup() {
			this.news.isOpenedPopupSearchTags = false;
		},
		onSelectTag(tag) {
			if (!this.bufferTags.find(item => item.id === tag.id)) {
				this.bufferTags.push(tag);
			}
		},
		onRemoveTag(tag) {
			const index = this.bufferTags.find(item => item.id === tag.id);

			if (index !== -1) {
				this.bufferTags.splice(index, 1);
			}
		},
		onClearTags() {
			this.bufferTags = [];
		},
	}
}
</script>

<style
	scoped
	lang="scss"
>
:deep(.popup__container) {
	width: 500px;
	overflow-y: hidden;

	@media (max-width: 517px) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		width: auto;
		max-height: none;
	}
}

:deep(.popup_opened > .popup__container) {}

.icon {
	color: $neutrals3;
}

.icon:before {
	font-size: 20px;
}


.news-search-tags {
	&__popup .popup__container {
		padding: 20px;
		max-height: 590px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		width: 100%;
	}

	&__media-tags {
		margin-top: 20px;
	}

	&__btn-open {
		display: flex;
		align-items: center;
		gap: 5px;
		cursor: pointer;
	}

	&__input {
		width: 100%;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding-top: 10px;
		position: sticky;
		bottom: 0;
		background: $dark;
		border-top: 1px solid #344859;
		flex-direction: row;
		gap: 10px;
	}

	&__footer-title {
		margin-bottom: 7px;
	}

	&__footer-btns {
		display: flex;
		align-items: flex-start;
		margin-left: auto;
		margin-top: 10px;
		&_not-empty {
			margin-top: 26px;
		}

	}
}

.news-filter-tools {
	display: flex;
	justify-content: flex-end;
	margin-block: 20px;

	&__left {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.tags-container {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 100px;
	max-height: 315px;

	@media (max-width: 517px) {
		max-height: calc(100vh - 126px);
		overflow-x: auto;
	}
}

.tag-header {
	position: sticky;
	top: 0;
	color: $neutrals3;
	background: $dark;
	z-index: 2;

	&>th {
		padding: 0 0 10px;
	}

	&>th:first-child {
		position: sticky;
		left: 0;
		background: $dark;
		z-index: 1;
	}

	&::after {
		content: '';
		position: absolute;
		left: -20px;
		right: -20px;
		bottom: 0;
		z-index: 1;
		display: block;
		border-bottom: 1px solid $dark-2;
	}
}

.tag-item {
	padding: 5px 0;
	cursor: pointer;

	&__header {
		min-width: 170px;
		padding: 6px 10px 7px;

		@media (max-width: 517px) {
			position: sticky;
			left: 0;
			background: $dark;
		}
	}

	&__count {
		min-width: 36px;
		padding: 4px 5px 5px;
		color: $neutrals3;
		text-align: center;
		transition: color .25s;
	}

	&:hover &__count {
		color: $white;
	}

	&:hover td {
		background: $dark-2;
	}
}

.media-service-item {
	display: flex;
	align-items: center;
	gap: 5px;
}
</style>
