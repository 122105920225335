<template>
    <div class="page-wrapper" :class="{mobile: store.isMobile}">
		<header class="header">
			<div class="header__container container">
				<NuxtLink to="/nft/" class="header-logo">
					<picture>
						<source media="(max-width:767px)" srcset="~/assets/images/svg/logo-color.svg">
						<img src="~/assets/images/svg/logo-lg.svg" alt="DOXXY" class="img img--contain">
					</picture>
				</NuxtLink>

				<div v-if="store.isMobile" class="news__filters">
					<client-only>
						<NewsSearchSources class="news__select" />
						<NewsSearchTags class="news__search" />
					</client-only>
				</div>
			</div>
		</header>

		<main v-if="store.isMobile">
			<NewsMediaTags :tags="selectedMediaTags" @remove-tag="onRemoveTag" @clear-tags="onClearTags"/>

			<div class="tab-switcher">
				<svg class="tab-switcher__item" :class="{'tab-switcher__item_active': news.activeTab === 'news'}" @click="news.activeTab = 'news'" height="29" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M14 10.5H18.5M14 13.5H18.5M3.5 20.25V5.25C3.5 5.05109 3.57902 4.86032 3.71967 4.71967C3.86032 4.57902 4.05109 4.5 4.25 4.5H20.75C20.9489 4.5 21.1397 4.57902 21.2803 4.71967C21.421 4.86032 21.5 5.05109 21.5 5.25V20.25L18.5 18.75L15.5 20.25L12.5 18.75L9.5 20.25L6.5 18.75L3.5 20.25ZM6.5 9H11V15H6.5V9Z"/>
				</svg>

				<svg class="tab-switcher__item" :class="{'tab-switcher__item_active': news.activeTab === 'other'}" @click="news.activeTab = 'other'" height="26" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.83333 2.5H3V8.33333H8.83333V2.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M18 2.5H12.1667V8.33333H18V2.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M18 11.6667H12.1667V17.5H18V11.6667Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M8.83333 11.6667H3V17.5H8.83333V11.6667Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>

			<section :class="nameClasses">
				<div class="news__left">
					<NewsListArticles/>
				</div>

				<div class="news__right">
					<slot />
					<FooterNews />
				</div>
			</section>
		</main>

		<main v-else>
			<section class="container" :class="nameClasses">
				<div class="news__left">
					<div class="news__filters">
						<client-only>
							<NewsSwitcherMarket/>
							<NewsSearchSources class="news__select"/>
							<NewsSearchTags class="news__search"/>
						</client-only>
					</div>

					<NewsMediaTags :tags="selectedMediaTags" @remove-tag="onRemoveTag" @clear-tags="onClearTags"/>

					<NewsListArticles/>
				</div>

				<div class="news__right">
                    <slot />
					<FooterNews />
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { useStore } from '~/stores/store';
import { useNews } from '~/stores/news';

export default {
    name: 'News',
    setup() {
        const store = useStore();
        const news = useNews();
        const api = useApi();

            useHead({
                link: [{
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Arimo&family=Exo+2&family=Fira+Sans&family=Hind+Siliguri&family=Roboto:wght@400;700&family=Titillium+Web&display=swap'
                }],
                script: [
                    {
                        src: 'https://telegram.org/js/telegram-web-app.js',
                        defer: true
                    }
                ]
            })

        return {
            store,
			news,
            api
        };
    },
    data: () => ({
		activeTab: 'news',
    }),
	computed: {
		nameClasses() {
			// console.log('nameClasses', this.store.isMobile)
			const obj = {
				news: true,
				'mobile': this.store.isMobile
			}

			obj['news_tab_' + this.news.activeTab] = true

			return obj;
		},
		selectedMediaTags() {
			return [...this.news.filters.services, ...this.news.filters.sources, ...this.news.filters.tags];
		},
	},
    watch: {
		'$route'(newValue, oldValue) {
			// console.log('%cwatch() $route.name', 'color:red', oldValue, newValue);
            this.news.previousRoute = oldValue;
		},
		'news.isOpen'(val) {
			const modals = document.querySelectorAll('.modal-overlay');
			if (val) {
				document.body.classList.add('modal-open');
				return;
			}
			if (modals.length < 2) {
				document.body.classList.remove('modal-open');
			}
		},
		'news.favorites': {
			handler(newVal) {
				// localStorage.setItem('news-favorites', JSON.stringify(newVal));
				const obj = {
					services: Array.from(newVal.services),
					sources: Array.from(newVal.sources),
				}
				localStorage.setItem('news-favorites', JSON.stringify(obj));
			},
			deep: true
		}
	},
    mounted() {
		try {
			const favorites = JSON.parse(localStorage.getItem('news-favorites'));

			if (favorites) {
				// this.news.favorites = favorites;
				this.news.favorites.services = new Set(favorites.services);
				this.news.favorites.sources = new Set(favorites.sources);
				this.news.initFavorites();
			}
		} catch (e) {
			console.error(e);
		}
    },
	methods: {
        onRemoveTag(tag) {
            this.news.removeTag(tag);
        },
        onClearTags() {
			this.news.clearFilters();
			this.news.setURL();
		},
	},
};
</script>

<style lang="scss" scoped>
//.header {
//	background-color: lightgreen;
//}
main {
	width: 100%;
	min-height: calc(100vh - 100px);

	@media (max-width: 600px) {
		overflow: hidden;
	}
}

.news {
    display: grid;
    grid-template-columns: 1fr 1fr;
	gap: 60px;

	@media (max-width: 600px) {
		grid-template-columns: 100vw 100vw;
		gap: 0;
		width: 100vw;
		transition: translate 0.5s;
	}

    &__line-select {
        align-items: center;
        display: flex;
    }

    &__left {
		position: relative;
		min-height: calc(100vh - 100px);
		max-height: calc(100vh - 100px);
		display: flex;
		flex-direction: column;

		@media (max-width: 600px) {
			min-height: calc(100vh - 124px);
			max-height: calc(100vh - 124px);
		}

		&:after {
			content: '';
			position: absolute;
			top: 59px;
			right: -22px;
			bottom: 15px;
			display: block;
			border-right: 3px solid #344859;

			@media (max-width: 600px) {
				content: none;
			}
		}
    }

	&__right {
		display: flex;
		flex-direction: column;
        justify-content: space-between;
		overflow-y: scroll;
		max-height: calc(100vh - 100px);
		padding-right: 20px;
		margin-right: -20px;

		@media (max-width: 600px) {
			max-height: calc(100vh - 124px);
			margin-left: 20px;
			margin-right: 0;
			padding-right: 20px;
		}
    }

    &__filters {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-left: 70px;
        padding-right: 20px;
		//line-height: 28px;

		@media (max-width: 600px) {
			margin: 0;
			padding: 0 10px;
		}
    }

    &__search {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-left: auto;
    }

	//&.mobile {
	//}

	//@at-root {
	//	a#{&} &__title {
	//		color: var(--colorBlack);
	//	}
	//}
}

.news_tab_other {
	@media (max-width: 600px) {
		translate: -100vw 0;
	}
}

.media-tags {
	margin: 0 0 20px 65px;
	//overflow-y: auto;
	//height: 100%;
	//min-height: 33px;
	//max-height: 300px;

	@media (max-width: 600px) {
		margin: 0 20px 20px 20px;
	}
}

//.news.mobile {
//	gap: 0;
//
//	.news__left {
//		&:after {
//			display: none;
//		}
//	}
//}

.tab-switcher {
	display: flex;
	gap: 10px;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;

	&__item {
		color: $neutrals3;
		transition: color .25s;

		&_active {
			color: #fff;
		}
	}
}
</style>
