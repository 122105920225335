<template>
    <component :is="tagName" class="checkbox" :class="classNames">
        <input class="checkbox__input" type="checkbox" :checked="isChecked" @change="onChange">
        <span class="checkbox__checkmark">
            <slot name="checkmark">
				<span v-if="modelValue === 2" style="margin-bottom: 2px">—</span>
                <svg v-else width="12" height="9" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 2L5.75 10L2 6.36364" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path></svg>
            </slot>
        </span>
        <slot />
    </component>
</template>

<script>
export default {
    name: 'CheckboxMulti',
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        align: {
            type: String,
            default: 'left'
        },
        clickOnlyInput: {
            type: Boolean,
            default: false
        },
    },
    computed: {
		isChecked() {
			return this.modelValue === 1 || this.modelValue === 2;
		},
        classNames() {
            return {
                'checkbox_align_right': this.align === 'right'
            }
        },
        tagName() {
            return this.clickOnlyInput ? 'div' : 'label';
        }
    },
    methods: {
        onChange(event) {
			// console.log('onChange', this.modelValue)
            let result = 1;

            switch (this.modelValue) {
                case 0:
                    result = 1;
                    break;

                case 1:
                case 2:
                    result = 0;
                    break;
            }
            this.$emit('update:modelValue', result);
        },
        onClick(event) {
			// console.log('onClick', this.modelValue, !this.modelValue)
            this.$emit('update:modelValue', !this.modelValue);
        }
    }
}
</script>

<style lang="scss" scoped>
/* Customize the label (the checkbox) */
.checkbox {
	position: relative;
	display: inline-flex;
	align-items: center;
	height: 16px;
    padding-left: 20px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: color .15s;
}

.checkbox__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.checkbox__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border: 1px solid $dark-2;
    border-radius: 3px;
    background-color: $dark;
    transition: background-color .15s, border-color .15s;
}
.checkbox__checkmark svg {
    scale: 0;
    transition: scale .2s;
}

.checkbox__input:checked ~ .checkbox__checkmark {
    border-color: $white;
    //background-color: $primary1;
}
.checkbox__input:checked ~ .checkbox__checkmark svg {
    scale: 1;
}

.checkbox_align_right {
    padding-left: 0;
    padding-right: 35px;
}
.checkbox_align_right .checkbox__checkmark {
    left: auto;
    right: 0;
}

.checkbox:hover {
    color: $white;
}
.checkbox:hover .checkbox__input ~ .checkbox__checkmark {
    border-color: $neutrals3;
}

.checkbox:hover .checkbox__input:checked ~ .checkbox__checkmark {
    border-color: $neutrals2;
}
</style>
