<template>
	<NewsSwitcher
        :model-value="news.filters.marketType"
        :select-items="marketTypeItems"
		class="news__line-select"
		divider
        @change="onChangeSelect"
	/>
</template>

<script>
import {useNews} from '~/stores/news';

export default {
	name: "NewsSwitcherMarket",
	setup() {
		const news = useNews();

		return {
			news
		};
	},
	data() {
		return {
			marketTypeItems: [
				{
					id: null,
					name: 'All',
				},
				{
					id: 1,
					name: 'Crypto',
				},
				{
					id: 2,
					name: 'NFT',
				},
			],

		}
	},
	methods: {
		onChangeSelect(value) {
			this.news.setMarket(value);
		},
	},
};
</script>

<style lang="scss" scoped>

</style>
