<template>
    <footer class="footer">
        <div class="footer__container">
            <div class="footer__left">
            </div>
            <div class="footer__row-hp">
                <span class="body-1 op-045">© DOXXY</span>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
	name: 'FooterNews',
    // setup() {
    //     const store = useStore();
    //
    //     return {store}
    // },
	// methods: {
	// 	scrollToTop() {
	// 		this.scrollToSmoothly(0, 1000);
	// 	},
	// 	scrollToSmoothly(pos, time) {
	// 		var currentPos = window.pageYOffset;
	// 		var start = null;
	// 		if (time == null) time = 500;
	// 		(pos = +pos), (time = +time);
	// 		window.requestAnimationFrame(function step(currentTime) {
	// 			start = !start ? currentTime : start;
	// 			var progress = currentTime - start;
	// 			if (currentPos < pos) {
	// 				window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
	// 			} else {
	// 				window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
	// 			}
	// 			if (progress < time) {
	// 				window.requestAnimationFrame(step);
	// 			} else {
	// 				window.scrollTo(0, pos);
	// 			}
	// 		});
	// 	},
	// },
};
</script>

<style lang="scss" scoped>
.footer {
    margin-top: auto;

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
        height: 70px;
		padding: 20px 0;
		//min-height: 80px;
		border-top: 1px solid $dark-2;
		gap: 2.4rem;

		@media (max-width: 767px) {
			flex-direction: column;
			gap: 1.6rem;
		}
	}


    &__link {
        color: $white;
        line-height: 2;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

	&__left {
		display: flex;
		gap: 30px;

		@media (max-width: 767px) {
			display: none;
		}
	}

    &__row {
        display: flex;
        gap: 2.4rem;

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 1.6rem;
        }

        &-hp {
            display: flex;
            gap: 3.2rem;
            @media (max-width: 767px) {
                gap: 1.6rem;
                flex-wrap: wrap;
            }
            @media (max-width: 389px) {
                max-width: 230px;
                justify-content: center;
            }
        }
    }
}
</style>
